<template>
  <div>
    <el-breadcrumb separator-icon="ArrowRight" style="margin: 16px">
      <el-breadcrumb-item :to="{ path: '/ygzd' }">返回</el-breadcrumb-item>

      <el-breadcrumb-item>设计师订单详情</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card style="margin: 15px; min-height: calc(100vh - 111px)">
      <div>
        <el-table v-loading="loading" :data="tableData" border max-height="705" show-overflow-tooltip
                  style="width: 100%">
          <el-table-column label="#" type="index"/>
          <el-table-column label="月份" prop="creationtime" width="100"/>
          <el-table-column label="#" type="index"/>-->
           <el-table-column label="订单类型" prop="serve" width="100"/>
           <el-table-column label="订单编号" prop="ordernumber" width="100"/>
           <el-table-column label="客户id" prop="customerID" width="100"/>
           <el-table-column label="订单创建时间" prop="creationtime" width="100"/>
           <el-table-column label="审核时间" prop="deliverytime" width="100"/>
           <el-table-column label="接单人" prop="writers" width="100"/>
           <el-table-column label="收费金额" prop="collection" width="100"/>
           <el-table-column label="派单金额" prop="payment" width="100"/>
           <el-table-column label="收款方式" prop="manner" width="100"/>
          <!--                操作栏-->
          <el-table-column label="驳回" width="130px">-->
          </el-table-column>
        </el-table>


        <!--分页-->
        <div style="margin: 10px 0">
          <el-pagination
              v-model:currentPage="currentPage"
              :page-size="pageSize"
              :page-sizes="[10, 20]"
              :total="total"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script src="../assets/js/adminygzdxq.js"></script>