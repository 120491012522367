import request from "@/utils/request";
import * as url from "url";

const {ElMessage} = require("element-plus");

export default {
    name: "adminkfzdxqq",

    components: {

    },
    // mounted() {
    //
    //     const userName = this.$route.query.userName;
    //
    //     console.log(userName); // 输出从上一个页面传递过来的 userName
    //     alert(userName)
    // },

    data() {

        const userName = this.$route.query.userName || '匿名用户';
        return {
            selectedYear: '', // 存储选中的年份
            selectedMonth: '', // 存储选中的月份
            dateRange: [], // 存储日期范围选择器的值
            creationtimeFilter: '', // 存储月份筛选器的值

            loading: true,

            disabled: false,
            disabled1: false,
            judge: false,
            dialogVisible: false,
            dialogVisible1: false,
            detailDialog: false,
            search: "",
            currentPage: 1,
            pageSize: 10,
            total: 0,
            tableData: [],
            tableData1: [],
            detail: {},
            form: {
                id: "",
                deliverytime:"",
                customer: "",
                writers: "",
                creationtime: "",
                customerID: "",
                finish: "",
                audit: "",
                remark: "",
                collection: "",
                payment: "",
                serve: "",
                demand: "",
                manner: "",
                settlement: "",
                incentive: "",
                ordernumber: "",
            },


            rules: {
            },
        };
    },

    created() {


        this.load();
        this.loading = true;
        setTimeout(() => {
            //设置延迟执行
            this.loading = false;
        }, 1000);

    },
    methods: {

        // 处理日期范围选择器值变化
        handleDateRangeChange(value) {
            // value 是一个数组，包含开始日期和结束日期
            // 在这里根据选中的日期范围进行数据筛选或发起查询
        },
        // 处理月份筛选器值变化
        handleCreationtimeFilterChange(value) {
            // value 是选中的月份
            // 在这里根据选中的月份进行数据筛选或发起查询
        },



        async load() {
            const userName = this.$route.query.userName;
            const creationtime = this.$route.query.creationtime;
            const settlement = this.$route.query.settlement;
            request.get("/building/xq", {
                params: {
                    pageNum: this.currentPage,
                    pageSize: this.pageSize,
                    Writers: userName,
                    Deliverytime: creationtime,
                    settlement: settlement,
                },
            }).then((res) => {
                console.log(res);
                this.tableData = res.data.records;
                this.total = res.data.total;
                this.loading = false;
            });
        },
        // async load1() {
        //
        //     const userName = this.$route.query.userName;
        //     const creationtime = this.$route.query.creationtime;
        //     const settlement = this.$route.query.settlement;
        //     // const creationtime = row.creationtime; // 获取creationtime的值
        //     // const settlement = row.settlement; // 获取remark的值
        //     const userData = JSON.parse(window.sessionStorage.getItem("user"));
        //     // const userName = row.customer;
        //     alert(userName)
        //     request.get("/building/xqq", {
        //         params: {
        //             pageNum: this.currentPage,
        //             pageSize: this.pageSize,
        //             Customer: userName,
        //             Deliverytime: creationtime,
        //             settlement: settlement,
        //         },
        //
        //     }).then((res) => {
        //         console.log(res);
        //
        //         this.tableData1 = res.data.records;
        //         this.total = res.data.total;
        //         this.loading = false;
        //         // this.goToPage('/kfzdxq');
        //
        //         // 打印tableData1的值
        //         console.log(this.tableData1);
        //
        //         this.$forceUpdate();
        //         this.dialogVisible = true;
        //
        //     });
        //
        //
        // },
        handleEdit(row) {
            //获取详情 //修改
            this.judge = true;
            this.dialogVisible1 = true;
            this.$nextTick(() => {
                this.$refs.form.resetFields();
                // 生拷贝
                this.form = JSON.parse(JSON.stringify(row));
                this.disabled1 = true;
            });
        },


        goToPage(url) {
            window.location.href = url;
        },
        closeDetailDialog() {
            this.detailDialog = false;
        },
        reset() {

            const userData = JSON.parse(window.sessionStorage.getItem("user"));
            const userName = userData.username;
            request.get("/building/findzd", {
                params: {
                    pageNum: 1,
                    pageSize: this.pageSize,
                    search: userName
                },
            }).then((res) => {
                console.log(res);
                this.tableData = res.data.records;
                this.total = res.data.total;
                this.loading = false;
            });
        },
        filterTag(value, row) {
            return row.finish === value;
        },
        filterTag1(value, row) {
            return row.audit === value;
        },
        filterTag2(value, row) {
            return row.settlement === value;
        },

        cancel() {
            this.$refs.form.resetFields();
            this.dialogVisible1 = false;
        },
        save(row){
            alert( row.settlement)
            alert( row.creationtime)
            request.put("/building/updateZD", row).then((res) => {
                console.log(res);
                if (res.code === "0") {
                    ElMessage({
                        message: "修改成功",
                        type: "success",
                    });
                    this.search = "";
                    this.load();
                    this.dialogVisible1 = false;
                } else {
                    ElMessage({
                        message: res.msg,
                        type: "error",
                    });
                }
            });
        },

        showDetail(row) {
            this.detailDialog = true;
            this.$nextTick(() => {
                this.detail = row;
            });
        },

        handleSizeChange(pageSize) {
            //改变每页个数
            this.pageSize = pageSize;
            this.load();
        },
        handleCurrentChange(pageNum) {
            //改变页码
            this.currentPage = pageNum;
            this.load();
        },
    },
};